import React, { Fragment } from "react";
import Helmet from "react-helmet";
import Header from "../components/Header";
import Container from "components/Container";
import "../assets/stylesheets/styles.scss";
import doggy from "../assets/images/doggy.png";
import leftWave from "../assets/images/left-wave.svg";
import rightSmallWave from "../assets/images/right-small-wave.svg";
import rightBigWave from "../assets/images/right-big-wave.svg";
import { Link } from "gatsby";

const complete = () => {
  return (
    <Fragment>
      <Helmet>
        <title>Contact Page</title>
      </Helmet>

      <div className="hero">
        <Header />
        <Container>
          <div className="hero__container">
            <div className="hero__img">
              <img className="hero__doggy" src={doggy}></img>
              <img className="hero__left-wave" src={leftWave}></img>
            </div>
            <div className="hero__content">
              <h1 className="hero__title">
                THANK YOU!<br></br>
              </h1>
              <p className="hero__description">
                We've received your claim and one of our team members will
                contact you as soon as possible.
              </p>

              <Link to="/" className="hero__cta">
                GO TO HOME PAGE
              </Link>

              <img
                className="hero__right-small-wave"
                src={rightSmallWave}
              ></img>
              <img className="hero__right-big-wave" src={rightBigWave}></img>
            </div>
          </div>
        </Container>
      </div>
      <div className="mobile-section">
        <div className="cta-container">
          <Link to="/" className="hero__cta">
            GO TO HOME PAGE
          </Link>
        </div>

        <h1 className="hero__title">
          THANK YOU<br></br>FOR YOUR SUBMISSION
        </h1>
      </div>
    </Fragment>
  );
};

export default complete;
